class CollapseCore {
  constructor(wrapper) {
    this.section = wrapper;
    if (!this.setVars()) return;

    this.setEvents();
  }

  setVars() {
    if (!this.section) return;

    this.collapse = this.section.querySelectorAll("[data-collapse-header]");
    this.collapseContent = this.section.querySelectorAll("[data-collapse-content]");
    this.settings = {
      count: this.collapse.length,
    };
    this.classes = {
      openClass: "open",
    };

    return true;
  }

  setEvents() {
    for (let i = 0; i < this.settings.count; i++) {
      this.collapse[i].addEventListener("click", event => {
        event.preventDefault();
        this.openCurrCollapse(event);
      });
    }
  }

  openCurrCollapse(event) {
    for (let i = 0; i < this.settings.count; i++) {
      if (event.currentTarget === this.collapse[i] && !this.collapse[i].hasClass(this.classes.openClass)) {
        this.collapse[i].addClass(this.classes.openClass);
        this.collapseContent[i].addClass(this.classes.openClass);
        this.collapseContent[i].style.maxHeight = `${this.collapseContent[i].scrollHeight}px`;
      } else {
        this.collapse[i].removeClass(this.classes.openClass);
        this.collapseContent[i].removeClass(this.classes.openClass);
        this.collapseContent[i].style.maxHeight = "0px";
      }
    }
  }
}

export default class Collapse {
  constructor() {
    this.sections = document.querySelectorAll("[data-collapse]");

    if (!this.sections.length) return;

    this.sections.forEach(section => {
      new CollapseCore(section);
    });
  }
}
