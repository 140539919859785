export default class Breadcrumb {
  constructor() {
    this.setVars();
    this.checkCart();
  }

  setVars() {
    this.breadcrumb = document.querySelector("[data-breadcrumb]");
    this.isCart = document.querySelector("[data-cart]");
    this.buttonBack = document.querySelector("[data-breadcrumb-back]");
    this.myAccount = document.querySelector("[data-my-account]");
    this.mySingleProduct = document.querySelector("[data-my-single-product]");
    this.containerContent = document.querySelector("[data-container]");
    this.pagination = document.querySelector("[data-pagination]");

    this.classes = {
      breadcrumbCard: "breadcrumb__cart",
      buttonBackActive: "breadcrumb__back--active",
      breadcrumbSingleProduct: "breadcrumb__singleProduct",
      container: "container",
      paginationActive: "breadcrumb__pagination--active",
    };
  }

  checkCart() {
    if (this.isCart) {
      this.breadcrumb.addClass(this.classes.breadcrumbCard);
      this.buttonBack?.addClass(this.classes.buttonBackActive);
    } else {
    }

    if (this.myAccount) {
      this.breadcrumb.addClass(this.classes.breadcrumbCard);
      this.buttonBack?.addClass(this.classes.buttonBackHide);
    }

    if (this.mySingleProduct) {
      this.breadcrumb.addClass(this.classes.breadcrumbCard);
      this.containerContent.removeClass(this.classes.container);
      this.pagination.addClass(this.classes.paginationActive);
    }
  }
}
