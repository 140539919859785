import anime from "animejs/lib/anime.es.js";

export class CollapseListCore {
  constructor(wrapper) {
    this.section = wrapper;

    if (!this.setVars()) return;

    this.setEvents();
  }

  setVars() {
    this.collapseListToggle = this.section.querySelector("[data-collapse-list-toggle]");
    this.collapseListItems = this.section.querySelector("[data-collapse-list-items]");
    this.header = document.querySelector("[data-header]");

    if (!this.collapseListToggle || !this.collapseListItems) {
      return;
    }

    this.classes = {
      collapseOpen: "open",
      collapseRotate: "rotate",
    };

    this.settings = {
      openDuration: 1500,
      startHeight: this.collapseListItems.clientHeight,
    };
    return true;
  }

  setEvents() {
    this.toggleList = this.toggleList.bind(this);

    this.collapseListToggle.addEventListener("click", this.toggleList);
  }

  toggleList(event) {
    event.preventDefault();

    if (this.collapseListItems.hasClass(this.classes.collapseOpen)) {
      this.closeList(this.collapseListItems);
      return;
    }

    this.settings.startHeight = this.collapseListItems.clientHeight;
    this.collapseListItems.addClass(this.classes.collapseOpen);
    this.collapseListToggle.addClass(this.classes.collapseRotate);
  }

  closeList(list) {
    const classList = this.classes.collapseOpen;
    const doneHeight = this.settings.startHeight;
    const section = this.section;
    const header = this.header;
    const collapseToggle = this.collapseListToggle;
    const classesRotate = this.classes.collapseRotate;
    anime({
      targets: this.collapseListItems,
      duration: this.settings.openDuration,
      height: [this.collapseListItems.clientHeight, doneHeight],
      easing: "easeInOutQuad",
      complete() {
        window.scroll({
          top: section.offsetTop - header.clientHeight,
          behavior: "smooth",
        });
        list.removeClass(classList);
        list.style.height = "";
        collapseToggle.removeClass(classesRotate);
      },
    });
  }
}

export default class CollapseList {
  constructor() {
    const sections = document.querySelectorAll("[data-collapse-list]");

    sections?.forEach(section => {
      new CollapseListCore(section);
    });
  }
}
