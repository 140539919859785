import Footer from "./site/Footer";
import Header from "./site/Header";
import Slider from "./sections/Slider";
import Breadcrumb from "./site/Breadcrumb";
import InputPlusMinus from "./site/InputPlusMinus";
import Collapse from "./site/collapse";
import CollapseList from "./site/collapseList";
import BackOrder from "./site/BackOrder";
import SingleProduct from "./site/SingleProduct";

class Core {
  constructor() {
    new Footer();
    new Header();

    new Slider();
    new Breadcrumb();
    new InputPlusMinus();
    new Collapse();
    new CollapseList();
    new BackOrder();
    new SingleProduct();
  }
}

new Core();
