class InputPlusMinusCore {
  constructor(wrapper) {
    this.section = wrapper;

    if (!this.setVars()) return;

    this.setEvents();
  }

  setVars() {
    this.input = this.section.querySelector("[data-input-plusMinus-field]");
    this.buttonPlus = this.section.querySelector("[data-input-plus]");
    this.buttonMinus = this.section.querySelector("[data-input-minus]");

    if (!this.input) {
      return;
    }

    this.max = parseInt(this.input.max);
    this.min = parseInt(this.input.min);

    return true;
  }

  setEvents() {
    this.buttonPlus.addEventListener("click", event => this.increment(event));
    this.buttonMinus.addEventListener("click", event => this.decrement(event));
  }

  increment(event) {
    event.preventDefault();

    const value = parseInt(this.input.value);

    if (isNaN(value)) {
      this.input.value = 1;
      return;
    }

    if (value >= this.max) {
      return;
    }

    this.input.value = value + 1;
    this.onChangeInput();
  }

  decrement(event) {
    event.preventDefault();
    const value = parseInt(this.input.value);

    if (isNaN(value)) {
      this.input.value = 1;
      return;
    }

    if (value <= this.min) {
      return;
    }

    this.input.value = value - 1;
    this.onChangeInput();
  }

  onChangeInput() {
    var event = new Event("change", { bubbles: true });
    this.input.dispatchEvent(event);
  }
}

export default class InputPlusMinus {
  constructor() {
    this.sections = document.querySelectorAll("[data-input-plusMinus]");

    if (this.sections) {
      this.sections.forEach(section => {
        new InputPlusMinusCore(section);
      });
    }
  }
}
