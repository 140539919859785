import anime from "animejs/lib/anime.es.js";

export default class Header {
  constructor() {
    if (!this.setVars()) return;

    this.setEvents();
  }

  setVars() {
    this.element = document.querySelector(".header");
    if (!this.element) return;

    this.headerDropdown = document.querySelectorAll("[data-toggle='dropdown']");
    this.searchForm = document.querySelector("[data-form-search]");
    this.clickSearch = document.querySelector("[data-click-search]");
    this.hamburger = document.querySelector("[data-page-header-hamburger]");
    this.menu = document.querySelector("[data-header-menu]");

    this.settings = {
      openDuration: 500,
    };

    this.classes = {
      hamburgerActive: "header__hamburger--active",
      menuActive: "header__menu--active",
      headerDropdownActive: "header__dropdown__menuWrapper--active",
    };

    return true;
  }

  setEvents() {
    this.headerDropdown?.forEach(dropdown => {
      dropdown.addEventListener("click", event => {
        this.toggleDropdownList(event);
      });
    });

    this.clickSearch.addEventListener("click", event => {
      this.toggleSeachForm(event);
    });

    this.hamburger.addEventListener("click", event => {
      this.toggleMenu(event);
    });

    this.element.addEventListener("click", e => {
      e.stopPropagation();
    });

    document.body.addEventListener("click", event => {
      this.hamburger.removeClass(this.classes.hamburgerActive);
      this.menu.removeClass(this.classes.menuActive);
    });
  }

  toggleSeachForm(event) {
    this.searchForm.style.marginTop = "-5px";
    let width = "0";
    const mediaMatch = window.matchMedia(`(max-width: 1024px)`);
    if (!mediaMatch.matches) {
      width = "-106px";
    }

    anime({
      targets: this.searchForm,
      duration: this.settings.openDuration,
      width: [0, "120px"],
      marginRight: ["15px", 0],
      opacity: [0, 1],
      marginLeft: [0, width],

      easing: "easeInOutQuad",
    });

    anime({
      targets: this.clickSearch,
      duration: this.settings.openDuration,
      opacity: [1, 0],
      width: ["100%", "0%"],

      easing: "easeInOutQuad",
    });
  }

  toggleMenu() {
    this.hamburger.toggleClass(this.classes.hamburgerActive);
    this.menu.toggleClass(this.classes.menuActive);
  }

  toggleDropdownList(event) {
    event.preventDefault();

    const mediaMatch = window.matchMedia(`(max-width: 1024px)`);
    if (!mediaMatch.matches) {
      return;
    }

    const { currentTarget } = event;
    const parentNodeElement = currentTarget.parentNode.parentNode.childNodes[3];
    parentNodeElement.toggleClass(this.classes.headerDropdownActive);

    if (parentNodeElement.hasClass(this.classes.headerDropdownActive)) {
      this.closeAllDropdown(currentTarget);
      this.openDrodpownList(parentNodeElement);
    } else {
      this.closeDropdownList(parentNodeElement);
    }
  }

  closeAllDropdown(currentTarget) {
    this.headerDropdown?.forEach(item => {
      const itemParent = item.parentNode.parentNode.childNodes[3];

      if (
        itemParent !== currentTarget.parentNode.parentNode.childNodes[3] &&
        itemParent.hasClass(this.classes.headerDropdownActive)
      ) {
        itemParent.removeClass(this.classes.headerDropdownActive);
        this.closeDropdownList(itemParent);
      }
    });
  }

  openDrodpownList(child) {
    child.style.position = "static";
    child.style.pointerEvents = "all";

    anime({
      targets: child,
      duration: this.settings.openDuration,
      height: [0, child.clientHeight],
      opacity: [0, 1],
      easing: "easeInOutQuad",
    });
  }

  closeDropdownList(child) {
    child.style.pointerEvents = "none";

    anime({
      targets: child,
      duration: this.settings.openDuration,
      height: [child.clientHeight, 0],
      opacity: [1, 0],

      easing: "easeInOutQuad",
      complete() {
        child.style.height = "";
        child.style.position = "absolute";
      },
    });
  }
}
