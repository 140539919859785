export default class BackOrder {
  constructor() {
    if (!this.setVars()) {
      return;
    }

    this.setEvents();
  }

  setVars() {
    this.variations = document.querySelectorAll(".variable-item");
    this.buttonAddToCard = document.querySelector(".single_add_to_cart_button");
    this.inputAddToCard = document.querySelector(".quantity");

    this.resetVarations = document.querySelector(".reset_variations");

    return true;
  }

  setEvents() {
    this.variations.forEach(item => {
      item.addEventListener("click", () => {
        this.toggleAddToCart();
      });
    });
  }

  toggleAddToCart() {
    setTimeout(() => {
      const isBackDore = document.querySelector("[data-product-backorder]");
      this.buttonAddToCard.style.display = isBackDore ? "none" : "block";
      this.inputAddToCard.style.display = isBackDore ? "none" : "block";
      this.resetVarations.style.display = isBackDore ? "none" : "block";
    }, 100);
  }
}
