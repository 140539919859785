class SliderCore
{
  constructor(wrapper)
  {
    this.section = wrapper;
    if (!this.setVars()) return;

    this.setEvents();
  }

  setVars()
  {
    this.items = this.section.querySelectorAll('.slider__item');

    return true;
  }

  setEvents()
  {

  }
}

export default class Slider
{
  constructor()
  {
    this.sections = document.querySelectorAll('.slider');
    if (!this.sections.length) return;

    this.sections.forEach((section) => {
      new SliderCore(section);
    });
  }
}
