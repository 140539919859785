export default class SingleProduct {
  constructor() {
    if (!this.setVars()) return;

    this.setEvents();
  }

  setVars() {
    this.forms = document.querySelectorAll(".wpcf7-form");
    if (!this.forms) {
      return;
    }

    return true;
  }

  setEvents() {
    this.forms.forEach(form => {
      let inputProductName = form.querySelector('input[name="product-name"]');
      let productName = document.querySelector("h1");
      inputProductName.value = productName.textContent;
      form.addEventListener("submit", () => {
        form.parentNode.parentNode.parentNode.style.maxHeight = "100%";
      });
    });
  }
}
